import React from 'react';

//@ts-ignore
import { ReactComponent as IconAppStore } from './icons/app-store.svg';
//@ts-ignore
import { ReactComponent as IconGooglePlay } from './icons/google-play.svg';
//@ts-ignore
import classes from './style.module.scss';

interface Props {
  onIosClick: () => void
  onAndroidClick: () => void
}

const InstallAppsButtons: React.FC<Props> = ({ onIosClick, onAndroidClick }) => {

  return (
    <div className={classes.buttons_block}>
      <IconAppStore
        className={classes.button}
        onClick={onIosClick}
      />
      <IconGooglePlay
        className={classes.button}
        onClick={onAndroidClick}
      />
    </div>
  );
};
export default InstallAppsButtons;
