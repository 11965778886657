import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import classes from './style.module.scss';

import { ReactComponent as Icon } from './icons/preloader.svg';

const Preloader = ({ className }) => (
  <div className={classNames(classes.wrapperSpinner, className)}>
    <span className={classes.spinner}>
      <Icon width="50px" height="50px" />
    </span>
  </div>
);

Preloader.propTypes = {
  className: PropTypes.string,
};

Preloader.defaultProps = {
  className: '',
};
export default Preloader;
