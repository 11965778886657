import React from 'react';
import classNames from 'classnames';
import { Textfit } from 'react-textfit';

import { t } from '@web-solutions/module-localization';

//@ts-ignore
import classes from './style.module.scss';

interface Props {
  onBtnClick: () => void;
  attempt: number;
  disable: boolean;
}

const InnerSpinButton: React.FC<Props> = ({ onBtnClick, attempt, disable }) => {

  const wrapClass = classNames(classes.wrap, {
    [classes.disable]: disable
  })

  return (
    <div className={wrapClass} onClick={onBtnClick}>
      <div className={classes.border} />
      <div className={classes.inner_spin_button}>
        <div className={classes.content}>
          <Textfit mode="single" max={34} className={classes.spin}>
            {t('spin_text')}
          </Textfit>
          <div className={classes.counter}>
            <p className={classes.attempt}>{attempt}</p>
          </div>
        </div>
      </div>
      <div className={classes.triangle} />
      <div className={classes.triangle_border} />
    </div>
  )
};

export default InnerSpinButton;