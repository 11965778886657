import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Analytics from '@web-solutions/module-analytics';
import Attribution from '@web-solutions/module-attribution';

import { APP_LINKS, } from 'src/constants/general';

import { load } from 'src/store/app/actions';
import { getMagnusToken } from 'src/store/app/utils';

import { LuckWheel } from 'src/screens/luck-wheel';

import packageInfo from '../package.json';

import configureStore from './store';
import { initLocalization } from './localization';

import 'src/styles/global.scss';
import 'src/styles/index.scss';

const App = (AppRouter: React.FC) => {
  const { store, persistor } = configureStore();

  Analytics.init(getMagnusToken(), packageInfo.version);
  Attribution.init(APP_LINKS);

  initLocalization();
  store.dispatch(load());

  return (
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRouter />
        </PersistGate>
      </Provider>
    </StrictMode>
  );
};

export const render = (AppRouter: React.FC) => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(App(AppRouter));
};

render(LuckWheel);
