import { AppLinks } from '@web-solutions/module-attribution'

export const APP_NAME = 'Artpix';

export const SUPPORT_EMAIL = '';

export const APP_LINKS: AppLinks = {
  ADJUST_LINK: 'https://artpix.go.link/p?adj_redirect_android=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dapp.artpix%26listing%3D_nstr',
  ADJUST_TOKEN: 'kkximn6',
};
