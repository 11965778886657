import _mapValues from 'lodash/mapValues';
import queryString from 'query-string';

import DeviceProps from '@magnus/react-native-device-props';
import UserProps from '@magnus/react-native-user-props';
import EvTruck from '@magnus/react-native-evtruck';

import Web2App from '@wowmaking/web2app';

export type AppLinks = {
  ADJUST_LINK?: string;
  ADJUST_TOKEN?: string;
  WEB_APP_LINK?: string;
  IOS_APP_ID?: string;
}

type LinkTarget = string;
type LinkTargetOptions = { promoOfferCode?: string };

let IDFM: string | undefined;
let appLinks: AppLinks | undefined;
let rcTarget: LinkTarget | undefined;
let rcTargetOptions: LinkTargetOptions | undefined;

export const LINK_TARGET = { // empty - by default - go to AF/Adjust link and then to store
  WEB: 'web', // target is web app
  PROMO: 'promo', // target is ios promo offer (inside appstore)
}

const AttributionModule = {
  init(links: AppLinks) {
    appLinks = links;

    DeviceProps.getIDFM().then((idfm) => {
      IDFM = idfm;
    });

    const params = queryString.parse(window.location.search);

    const attribution = _mapValues({
      utm_source: params.utm_source || '',
      campaign_name: params.campaign_name || '',
      campaign_id: params.campaign_id || '',
      adset_name: params.adset_name || '',
      adset_id: params.adset_id || '',
      ad_name: params.ad_name || '',
      ad_id: params.ad_id || '',
      http_referer: window.document.referrer || '',
    }, String);

    EvTruck.trackWebAttribution(attribution);

    Web2App.init({
      onChange: (system, p) => {
        // оборачиваем в массив, в будущем сможем поддерживать несколько пикселей на сайте и тп
        EvTruck.trackWebMeasurements({
          external_id: Web2App.getAttribution().web_external_id,
          [system]: [p],
        });
      },
    });

    if (params.target !== LINK_TARGET.WEB) {
      if (appLinks.ADJUST_LINK && appLinks.ADJUST_TOKEN) {
        return Web2App.trackAdjustImpression(appLinks.ADJUST_TOKEN);
      }
    }
  },

  setRemoteConfigTarget(target: string, options?: { promoOfferCode?: string }) {
    rcTarget = target;
    rcTargetOptions = options;
  },

  async getLinkToApp() {
    const source = 'sub';

    if (!appLinks) {
      return Promise.reject();
    }

    const up = await UserProps.fetch() as { utm_source: string, campaign_id: string, target: string, };

    const w = appLinks.WEB_APP_LINK
      ? Promise.resolve(
        Object.assign(
          String(queryString.stringifyUrl({
            url: appLinks.WEB_APP_LINK,
            query: {
              idfm: IDFM,
              source,
              utm_source: up.utm_source,
              campaign_id: up.campaign_id,
            },
          })),
          { target: LINK_TARGET.WEB }
        )
      ) : null;

    if ((up.target === LINK_TARGET.WEB || rcTarget === LINK_TARGET.WEB) && w) {
      return w;
    } else if (rcTarget === LINK_TARGET.PROMO && rcTargetOptions?.promoOfferCode && appLinks.IOS_APP_ID) {
      return Web2App.createAdjustLink(
        appLinks.ADJUST_LINK,
        appLinks.ADJUST_TOKEN,
        {
          uuid: IDFM,
          source: 'promo',
          invite: 'offer',
          adj_redirect_ios: queryString.stringifyUrl({
            url: 'https://apps.apple.com/redeem',
            query: {
              ctx: 'offercodes',
              id: appLinks.IOS_APP_ID,
              code: rcTargetOptions?.promoOfferCode,
            },
          }),
        });
    } else if (appLinks.ADJUST_LINK && appLinks.ADJUST_TOKEN) {
      return Web2App.createAdjustLink(appLinks.ADJUST_LINK, appLinks.ADJUST_TOKEN, { uuid: IDFM, source });
    } else {
      return w || Promise.reject();
    }
  },
};

export default AttributionModule;
