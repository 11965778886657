import Localization from '@web-solutions/module-localization';

import { APP_NAME, SUPPORT_EMAIL } from 'src/constants/general';

const resources = {
  am: {
    translation: require('./am.json'),
  },
  ar: {
    translation: require('./ar.json'),
  },
  as: {
    translation: require('./as.json'),
  },
  az: {
    translation: require('./az.json'),
  },
  be: {
    translation: require('./be.json'),
  },
  bh: {
    translation: require('./bh.json'),
  },
  bn: {
    translation: require('./bn.json'),
  },
  cs: {
    translation: require('./cs.json'),
  },
  de: {
    translation: require('./de.json'),
  },
  el: {
    translation: require('./el.json'),
  },
  en: {
    translation: require('./en.json'),
  },
  es: {
    translation: require('./es.json'),
  },
  fa: {
    translation: require('./fa.json'),
  },
  ff: {
    translation: require('./ff.json'),
  },
  fr: {
    translation: require('./fr.json'),
  },
  gu: {
    translation: require('./gu.json'),
  },
  ha: {
    translation: require('./ha.json'),
  },
  hi: {
    translation: require('./hi.json'),
  },
  hu: {
    translation: require('./hu.json'),
  },
  id: {
    translation: require('./id.json'),
  },
  ig: {
    translation: require('./ig.json'),
  },
  it: {
    translation: require('./it.json'),
  },
  ja: {
    translation: require('./ja.json'),
  },
  jv: {
    translation: require('./jv.json'),
  },
  kn: {
    translation: require('./kn.json'),
  },
  ko: {
    translation: require('./ko.json'),
  },
  mg: {
    translation: require('./mg.json'),
  },
  ml: {
    translation: require('./ml.json'),
  },
  mr: {
    translation: require('./mr.json'),
  },
  ms: {
    translation: require('./ms.json'),
  },
  my: {
    translation: require('./my.json'),
  },
  ne: {
    translation: require('./ne.json'),
  },
  nl: {
    translation: require('./nl.json'),
  },
  om: {
    translation: require('./om.json'),
  },
  or: {
    translation: require('./or.json'),
  },
  pa: {
    translation: require('./pa.json'),
  },
  pl: {
    translation: require('./pl.json'),
  },
  pr: {
    translation: require('./pr.json'),
  },
  ps: {
    translation: require('./ps.json'),
  },
  ro: {
    translation: require('./ro.json'),
  },
  ru: {
    translation: require('./ru.json'),
  },
  sd: {
    translation: require('./sd.json'),
  },
  sh: {
    translation: require('./sh.json'),
  },
  si: {
    translation: require('./si.json'),
  },
  sn: {
    translation: require('./sn.json'),
  },
  su: {
    translation: require('./su.json'),
  },
  ta: {
    translation: require('./ta.json'),
  },
  te: {
    translation: require('./te.json'),
  },
  th: {
    translation: require('./th.json'),
  },
  tl: {
    translation: require('./tl.json'),
  },
  tr: {
    translation: require('./tr.json'),
  },
  uk: {
    translation: require('./uk.json'),
  },
  ur: {
    translation: require('./ur.json'),
  },
  uz: {
    translation: require('./uz.json'),
  },
  vi: {
    translation: require('./vi.json'),
  },
  yo: {
    translation: require('./yo.json'),
  },
  za: {
    translation: require('./za.json'),
  },
  zh: {
    translation: require('./zh.json'),
  },
};

export function initLocalization() {
  Localization.init(resources, { appName: APP_NAME, supportEmail: SUPPORT_EMAIL });
}
