import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import { createAction } from 'redux-actions';

import RemoteConfig from '@web-solutions/module-remote-config';

import { SET } from './types';

const REMOTE_CONFIG_CACHE_TIME = 60;

export const setRemoteConfig = createAction(SET, (cfg) => ({ cfg }));

let _validate;

export const init = (magnusToken, initialState, { validate } = {}) => (dispatch, getState) => {
  const {
    remoteConfig,
  } = getState();

  return RemoteConfig.fetch(magnusToken, initialState, REMOTE_CONFIG_CACHE_TIME)
    .catch((err) => {
      console.log('[ERROR FETCH MUTATOR]', err.message);
      return _isEmpty(_omit(remoteConfig, '_persist')) ? initialState : remoteConfig;
    })
    .then((cfg) => {
      dispatch(setRemoteConfig(cfg));
      _validate = validate;
      validate?.(cfg);
      return cfg;
    });
};

export const reInit = () => (dispatch, getState) => {
  return RemoteConfig.activate()
    .then((cfg) => {
      dispatch(setRemoteConfig(cfg));
      _validate?.(cfg);
    })
    .catch((err) => {
      console.log('[ERROR REINIT MUTATOR]', err.message);
    });
};
