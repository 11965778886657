import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './style.module.scss';

const Text = ({ children, className }) => {
  const textStyle = classNames([classes.text, className]);
  return <p className={textStyle}>{children}</p>;
};

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.any,
};

Text.defaultProps = {
  className: null,
};

export default Text;
