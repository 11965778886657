
import { useSelector } from 'react-redux';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';

import { INITIAL_CONFIG } from 'src/constants/remote-config';

export const useRemoteConfig = () => {
  return useSelector(remoteConfigSelector) as typeof INITIAL_CONFIG;
}
