import React from 'react';
import className from 'classnames';
import { Textfit } from 'react-textfit';

import { t } from '@web-solutions/module-localization';

import { Portal, Title, Button } from 'core/ui-elements';

import { useHeight } from 'core/hooks/use-height';

import InstallAppsButtons from '../install-apps-buttons';
import { Rays } from '../rays';

import { IMAGES } from './images';

// @ts-ignore
import classes from './style.module.scss';

interface Props {
  prise: string;
  onBtnClick: () => void;
  onAndroidClick: () => void;
  onIosClick: () => void;
  active: boolean;
}

const tKey = 'win_modal';

const WinModal: React.FC<Props> = ({ prise, onBtnClick, onIosClick, onAndroidClick, active }) => {
  const containerHeight = useHeight();

  const containerClass = className(classes.container, {
    [classes.active]: active
  })

  return (
    <Portal>
      <div className={containerClass} style={{ height: containerHeight }}>
        <div className={classes.modal}>
          <div className={classes.rays_wrap}>
            <Rays />
          </div>
          <div className={classes.confetti} />
          <div className={classes.content}>
            <div className={classes.header}>
              <Title level='h1' className={classes.title}>
                <Textfit mode="multi">
                  {t(`${tKey}.${prise}.title`)}
                </Textfit>
              </Title>
              <Textfit mode="multi" className={classes.sub_title}>
                {t(`${tKey}.sub_title`)}
              </Textfit>
            </div>
            <img
              src={IMAGES[prise]?.src}
              srcSet={IMAGES[prise]?.srcSet}
              alt={prise}
            />
            <div className={classes.footer}>
              <Button
                title={
                  <Textfit mode="single">
                    {t(`${tKey}.btn_text`)}
                  </Textfit>
                }
                onClick={onBtnClick}
                className={classes.button}
              />
              <InstallAppsButtons onIosClick={onIosClick} onAndroidClick={onAndroidClick} />
            </div>
          </div>
        </div>
      </div>

    </Portal>
  )
}
export default WinModal;