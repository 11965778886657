import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './style.module.scss';

const Block = ({ children, className, ...rest }) => (
  <div className={classNames(classes.wrap, className)} {...rest}>
    {children}
  </div>
);

Block.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Block.defaultProps = {
  className: null,
  children: null,
};

export default Block;
