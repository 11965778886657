import React from 'react';

import PrisesWheel from '../prisesWheel';
import Dots from '../dots';

//@ts-ignore
import classes from './style.module.scss';

interface Props {
  deg: number;
}

const Wheel: React.FC<Props> = ({ deg }) => {

  return (
    <div className={classes.container} >
      <div className={classes.border}></div>
      <div className={classes.wrap}>
        <Dots />
      </div>
      <div className={classes.wrap} style={{ transform: `rotate(${deg}deg)` }}>
        <PrisesWheel />
      </div>
    </div>
  );
};

export default Wheel;