//@ts-nocheck
import { ImagesMap } from 'core/interfaces/images';

// common
import IPHONE_1X from './iphone.webp';
import IPHONE_2X from './iphone@2x.webp';
import IPHONE_3X from './iphone@3x.webp';

import UNLUCK_1X from './unluck.webp';
import UNLUCK_2X from './unluck@2x.webp';
import UNLUCK_3X from './unluck@3x.webp';

import WATCH_1X from './watch.webp';
import WATCH_2X from './watch@2x.webp';
import WATCH_3X from './watch@3x.webp';

import AIRPODS_1X from './airpods.webp';
import AIRPODS_2X from './airpods@2x.webp';
import AIRPODS_3X from './airpods@3x.webp';


export const IMAGES: ImagesMap = {
  IPHONE: {
    src: IPHONE_1X,
    srcSet: `${IPHONE_1X}, ${IPHONE_2X} 2x, ${IPHONE_3X} 3x`
  },
  AIRPODS: {
    src: AIRPODS_1X,
    srcSet: `${AIRPODS_1X}, ${AIRPODS_2X} 2x, ${AIRPODS_3X} 3x`
  },
  UNLUCK: {
    src: UNLUCK_1X,
    srcSet: `${UNLUCK_1X}, ${UNLUCK_2X} 2x, ${UNLUCK_3X} 3x`
  },
  WATCH: {
    src: WATCH_1X,
    srcSet: `${WATCH_1X}, ${WATCH_2X} 2x, ${WATCH_3X} 3x`
  },
};