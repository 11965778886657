import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Textfit } from 'react-textfit';

import Analytics from '@web-solutions/module-analytics';

import { tm } from '@web-solutions/module-localization';

import { Title } from 'core/ui-elements';

import { useHeight } from 'core/hooks/use-height';

import { EVENT_ACTION } from 'src/constants/events';

import { useRemoteConfig } from 'src/hooks/use-remote-config';

import { downloadApp } from 'src/store/app/actions';

import Wheel from './components/wheel';
import InstallAppsButtons from './components/install-apps-buttons';
import SpinButton from './components/spinButton';
import { Rays } from './components/rays';
import WinModal from './components/win-modal';

// @ts-ignore
import classes from './style.module.scss';

type PrisesDegs = {
  unlucky: number[],
  iphone: number,
  airpods: number,
}

const prisesDegs: PrisesDegs = {
  unlucky: [60, 180, 300],
  iphone: 60,
  airpods: 30,
}

const DEFAULT_DEG = 720;

export const LuckWheel: React.FC = () => {

  const [deg, setDeg] = useState(0);
  const [attempt, setAttempt] = useState(2);
  const [disable, setDisable] = useState(false);
  const [isWin, setIsWin] = useState(false);
  const dispatch = useDispatch();
  const containerHeight = useHeight();

  const { prise, pageTitle } = useRemoteConfig();

  useEffect(() => {
    Analytics.trackEvent('LUCK_WHEEL', EVENT_ACTION.OPEN);
  }, []);

  useEffect(() => {
    if (isWin) {
      Analytics.trackEvent('LUCK_WHEEL', 'pop_up_open', { product: prise });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWin]);

  const handleDownloadClick = () => {
    Analytics.trackEvent('LUCK_WHEEL', 'pop_up_click', { product: prise });
    handleBtnClick();
  }

  const handleBtnClick = () => {
    dispatch(downloadApp());
  };

  const handleSpinClick = () => {
    setDisable(true);
    if (attempt === 2) {
      Analytics.trackEvent('LUCK_WHEEL', 'spin_first_click');
      const timer = setTimeout(() => {
        setDisable(false);
        clearTimeout(timer);
      }, 4000);

      setAttempt(attempt - 1);
      setDeg(arrayRandElement(prisesDegs.unlucky));
    }
    else if (attempt === 1) {
      Analytics.trackEvent('LUCK_WHEEL', 'spin_second_click');
      const timer = setTimeout(() => {
        setIsWin(true);
        clearTimeout(timer);
      }, 4000);
      setAttempt(attempt - 1);
      prise === 'iphone' ? setDeg(deg + DEFAULT_DEG + prisesDegs.iphone) : setDeg(deg + DEFAULT_DEG + prisesDegs.airpods);
    }
  }

  return (
    <div className={classes.luck_wheel} style={{ height: containerHeight }}>
      <div className={classes.header}>
        <Title level='h1' className={classes.title}>
          <Textfit mode="multi">
            {tm(pageTitle, 'luck_wheel_title')}
          </Textfit>
        </Title>
        <InstallAppsButtons onIosClick={handleBtnClick} onAndroidClick={handleBtnClick} />
      </div>
      <div className={classes.rays_wrap}>
        <Rays />
      </div>
      <div className={classes.wheel_wrap}>
        <Wheel deg={deg} />
      </div>
      <SpinButton
        onBtnClick={handleSpinClick}
        attempt={attempt}
        disable={disable}
        stopBlinking={disable}
      />
      <WinModal
        prise={prise}
        onBtnClick={handleDownloadClick}
        onAndroidClick={handleDownloadClick}
        onIosClick={handleDownloadClick}
        active={isWin}
      />
    </div>
  );
};

const arrayRandElement = (arr: number[]) => {
  const random = Math.floor(Math.random() * arr.length);
  return arr[random] + DEFAULT_DEG;
}