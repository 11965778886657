import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './style.module.scss';

const Input = forwardRef(({ className, ...rest }, ref) => (
  <input ref={ref} className={classNames(classes.input, className)} {...rest} />
));

Input.propTypes = {
  className: PropTypes.any,
};

Input.defaultProps = {
  className: null,
};

export default Input;
