import { LString } from 'core/constants/remote-config';
export interface RemoteConfig {
  pageTitle: LString,
  prise: 'iphone' | 'airpods',
}

export const INITIAL_CONFIG: RemoteConfig = {
  pageTitle: { en: '' },
  prise: 'iphone',
};
