import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import copyToClipboard from 'copy-to-clipboard';

import LinkButton from '../link-button';

import classes from './style.module.scss';

const LinkCopy = ({
  title,
  link,
  className,
  onClick,
}) => {

  const [copied, setCopied] = useState(false);

  const handleCopyClick = useCallback(() => {
    copyToClipboard(link);
    setCopied(true);
    onClick?.();
  }, [link, onClick]);


  return (
    <LinkButton className={classNames(classes.root, className)} onClick={handleCopyClick}>
      <span>{title}</span>
      {copied ? ' ✓' : ' ❯'}
    </LinkButton>
  );
}

LinkCopy.propTypes = {
  className: PropTypes.string,
};

LinkCopy.defaultProps = {
  className: null,
};

export default LinkCopy;
