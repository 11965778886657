import _map from 'lodash/map';
import React from 'react';

import { IMAGES } from './images';

//@ts-ignore
import classes from './style.module.scss';


const UniquePrises: React.FC = () =>
(
  <>
    {_map(IMAGES, (v, k) => (
      <div className={classes.sector} key={k}>
        <img src={v.src} srcSet={v.srcSet} alt='prise' className={classes.prise} />
      </div>
    ))}
  </>
);

export default UniquePrises;