import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './style.module.scss';

const Title = ({ level, className, children }) => {
  switch (level) {
    case 'h1':
      return (
        <h1 className={classNames([classes.title_h1, className])}>{children}</h1>
      );
    case 'h2':
      return (
        <h2 className={classNames([classes.title_h2, className])}>{children}</h2>
      );
    case 'h3':
      return (
        <h3 className={classNames([classes.title_h3, className])}>{children}</h3>
      );
    default:
      return null;
  }
};

Title.propTypes = {
  level: PropTypes.oneOf(['h1', 'h2', 'h3']),
  className: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

Title.defaultProps = {
  level: 'h3',
  className: null,
};

export default Title;
