import React from 'react';

import UniquePrises from '../uniquePrises';

//@ts-ignore
import classes from './style.module.scss';


const PrisesWheel: React.FC = () => (
  <div className={classes.wrap}>
    <div className={classes.wheel}>
      <UniquePrises />
      <UniquePrises />
      <UniquePrises />
    </div>
  </div>

);

export default PrisesWheel;