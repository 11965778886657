import React from 'react';

import Dot from '../dot';

//@ts-ignore
import classes from './style.module.scss';

const total = 34;

const Dots: React.FC = () => {
  const dots: JSX.Element[] = [];

  for (let i = 0; i < total; i++) {
    dots.push(<Dot key={i} />);
  }

  return <div className={classes.dots}>{dots}</div>;
};

export default Dots;
