import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './style.module.scss';

const Checkbox = ({ isChecked, onChange, classNameCheckbox }) => (
  <div className={classes.checkbox}>
    <input type="checkbox" id="checkbox" defaultChecked={isChecked} onChange={onChange} />
    <span className={classNames(classes.checkbox__mark, classNameCheckbox)} />
  </div>
);

Checkbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  classNameCheckbox: PropTypes.string,
};

Checkbox.defaultProps = {
  classNameCheckbox: '',
};

export default Checkbox;
