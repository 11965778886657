import React from 'react';
import classNames from 'classnames';

import InnerSpinButton from '../innerSpinButton';

//@ts-ignore
import classes from './style.module.scss';

interface Props {
  onBtnClick: () => void;
  attempt: number;
  disable: boolean;
  stopBlinking: boolean;
}

const SpinButton: React.FC<Props> = ({ onBtnClick, attempt, disable, stopBlinking }) => {
  
  const wrapClass = classNames(classes.wrap, {
    [classes.stop_blink]: stopBlinking
  })

  return (
    <div className={wrapClass}>
      <div className={classes.spin_button}>
        <div className={classes.triangle} />
      </div>
      <InnerSpinButton onBtnClick={onBtnClick} attempt={attempt} disable={disable} />
    </div>
  );
};

export default SpinButton;