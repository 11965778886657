//@ts-nocheck
import { ImagesMap } from 'core/interfaces/images';

import IPHONE_1X from './iphone.webp';
import IPHONE_2X from './iphone@2x.webp';
import IPHONE_3X from './iphone@3x.webp';

import AIRPODS_1X from './airpods.webp';
import AIRPODS_2X from './airpods@2x.webp';
import AIRPODS_3X from './airpods@3x.webp';

export const IMAGES: ImagesMap = {
  iphone: {
    src: IPHONE_1X,
    srcSet: `${IPHONE_1X}, ${IPHONE_2X} 2x, ${IPHONE_3X} 3x`
  },
  airpods: {
    src: AIRPODS_1X,
    srcSet: `${AIRPODS_1X}, ${AIRPODS_2X} 2x, ${AIRPODS_3X} 3x`
  },
};