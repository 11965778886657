import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Flare from './flare';

import classes from './style.module.scss';

import { ReactComponent as IconArrow } from './icons/right-arrow.svg';

const Button = ({
  id,
  onClick,
  title,
  disabled,
  className,
  type,
  flare,
  titleClassName,
  iconRight,
}) => (
  <button
    id={id}
    type={type}
    onClick={onClick}
    className={classNames(classes.button, className)}
    disabled={disabled}
  >
    <div className={classNames(classes.button__title, titleClassName)}>
      <span>{title}</span>
    </div>
    {iconRight && <IconArrow className={classes['button__right-icon']} alt="arrow" />}
    {!disabled && flare ? <Flare /> : null}
  </button>
);

Button.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  type: PropTypes.string,
  flare: PropTypes.bool,
  iconRight: PropTypes.bool
};

Button.defaultProps = {
  id: null,
  onClick: null,
  className: null,
  titleClassName: null,
  disabled: false,
  type: 'button',
  flare: false,
  iconRight: false
};

export default Button;
