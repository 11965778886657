import React, { useEffect, useId, useRef } from 'react';
import PropTypes from 'prop-types';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { Button } from 'core/ui-elements';

import 'swiper/css';
import 'swiper/css/pagination';

import './style.scss';
import classes from './style.module.scss';

SwiperCore.use([Pagination]);

const SwiperSlider = ({
  slides,
  className,
  onClick,
  onClickButton,
  isButton,
  onSwipe,
  ...other
}) => {

  const wrapStyle = classNames([
    classes.wrap,
    className,
    { 'wrap-with-button': isButton },
  ]);
  const swiperRef = useRef(null);
  const swiperId = useId();

  useEffect(() => {
    const element = document.getElementById(swiperId);

    element?.addEventListener("touchmove", (e) => {
      e.preventDefault();
    });
  });

  const handleBtnClick = () => {
    const { swiper } = swiperRef.current;
    const isLastSlide = swiper.isEnd;

    if (isLastSlide) {
      onClickButton();
    } else {
      swiper.slideNext();
    }
  };

  const renderSlides = () =>
    slides.map((slide, i) => (
      <SwiperSlide key={`slide-${i}`} onClick={onClick}>
        {slide}
      </SwiperSlide>
    ));

  const handleSwipe = () => {
    const { swiper } = swiperRef.current;
    onSwipe(swiper.activeIndex);
  }

  return (
    <div className={wrapStyle} id={swiperId}>
      <Swiper
        centeredSlides
        ref={swiperRef}
        pagination
        onActiveIndexChange={handleSwipe}
        {...other}
      >
        {renderSlides()}
      </Swiper>
      {isButton && (
        <Button
          flare
          title={t('_continue')}
          onClick={handleBtnClick}
          iconRight={false}
          className={classes.button}
        />
      )}
    </div>
  );
};

SwiperSlider.propTypes = {
  slides: PropTypes.array.isRequired,
  className: PropTypes.any,
  isButton: PropTypes.bool,
  onClick: PropTypes.func,
  onClickButton: PropTypes.func,
  onSwipe: PropTypes.func,
};

SwiperSlider.defaultProps = {
  className: null,
  isButton: false,
  onClick: () => { },
  onClickButton: () => { },
  onSwipe: () => { },
};

export default SwiperSlider;
